import React from "react"
import { navigate } from "../../.cache/gatsby-browser-entry"
import {isMobile} from 'react-device-detect'
import {css} from '@emotion/core'
import Img from 'gatsby-image'

class ComicPromo5 extends React.Component {

  constructor(props) {
    super(props)
    this.state = { hover: false, mobileSelect: false }
    this.wrapperRef = React.createRef()
  }

  render() {

    const it = this

    function selectMobile() {
      if (isMobile) it.setState( {mobileSelect:true} )
    }

    function showContent() {
      it.setState({ hover: true })
    }

    function hideContent() {
      it.setState({ hover: false, mobileSelect: false })
    }

    function handleClick(event) {
      event.preventDefault()
      if (!isMobile || it.state.mobileSelect) {
        navigate(comic.fields.slug)
      } else {
        selectMobile()
      }
    }

    const { comic } = this.props

    return (
      <div
        ref={this.wrapperRef}
        role={'button'}
        tabIndex={0}
        className={`comic-promo-link-wrapper ${it.state.hover ? 'reveal' : ''}`}
        onKeyDown={handleClick}
        onClick={handleClick}
        onMouseEnter={showContent}
        onMouseLeave={hideContent}>

        <div className={"comic-promo-link drop-shadow"}>

          <div className={"comic-promo-wrapper"}>

            <div className={"comic-promo-image-wrapper comic-promo-front"} css={css`position:relative;`}>

              <Img
                alt={comic.frontmatter.title}
                fluid={
                  (comic.frontmatter.promo)
                    ? comic.frontmatter.promo.banner.fluid
                    : comic.frontmatter.image.childImageSharp.fluid}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%"
                }}
              />
              <div className={"comic-promo-title-wrapper-table"}>
                <div className={"comic-promo-title-wrapper-table-cell"}>
                  <div className={"comic-promo-title-wrapper"}>
                      <span className={"comic-promo-title fx-shadow-hard"}>
                        {comic.frontmatter.title}
                      </span>
                  </div>
                </div>
              </div>

            </div>

            <div className={"comic-promo-description-wrapper comic-promo-back"}>
              <div className={"comic-promo-description"}>
                {comic.frontmatter.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ComicPromo5