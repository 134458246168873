import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ComicPromoList from "../components/comic-promo-list"
import PageHeader from "../components/headers/page-header"

class BlogIndex extends React.Component {
	render() {
		const { data } = this.props
		const publishedComics = data.publishedComics.edges

		return (
			<Layout location={this.props.location}>
				<SEO
					title={'Comics'}
					description={`Original Comics & Graphic Novels about Conspiracy Theories and the Occult. Published by Paranoid American.`}
				/>

				<PageHeader>Comics</PageHeader>

				{/* Main list of comics */}
				<ComicPromoList series={publishedComics} />

			</Layout>
		)
	}
}

export default BlogIndex

export const pageQuery = graphql`
  query {    
	  # Published Comics Series
	  publishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}}}) {
      edges {
        node {
          id
          fields {slug }
          ...ComicSeriesFrontmatter
        }
      }
    }
  }
`
