import React from "react"
import ComicPromo5 from "./comic-promo-5"

class ComicPromoList extends React.Component {

  constructor(props) {
    super(props)
    this.state = { hover:false }
  }

  render() {

    const { series } = this.props

    return (
      <div className={'comic-promo-list'}>
        {series.map(({ node }) => {
          return (
            <ComicPromo5 key={node.fields.slug} comic={node} />
          )
        })}
      </div>
    )
  }
}

export default ComicPromoList